<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="6" style="display: block">
          <a-form-item label="名称">
            <a-input placeholder="Name" v-model="searchForm.Name" />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="状态">
            <a-input placeholder="Status" v-model="searchForm.Status" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="建立时间">
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick"> 查询 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a
            :style="{ marginLeft: '8px', fontSize: '12px' }"
            @click="expand = !expand"
          >
            {{ expand ? "收起" : "展开" }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>

    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom: 16px" type="flex" justify="end">
        <a-col>
          <a-button icon="plus" type="primary" @click="addClick" v-if="$root.$data.privateState.action.includes('/Channel/Create') || $root.$data.privateState.role === 'SuperAdmin'">添加渠道</a-button>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="column-height"
                />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'default'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'default'"
                      >默认</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'middle'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'middle'"
                      >中等</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'small'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'small'"
                      >紧凑</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"
                @click="
                  () => {
                    screenfull.toggle();
                    isFullscreen = !isFullscreen;
                  }
                "
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                icon="reload"
                @click="searchClick"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group
                    :value="showColumns"
                    @change="changeColumns"
                  >
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{ it }}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="setting"
                />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="(record) => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #channelPublicKey="channelPublicKey, data">
          <a-popover v-if="channelPublicKey" title="重新上传">
            <template slot="content">
              <p>是否重新上传渠道Pem？</p>
              <a-upload
                name="file"
                :file-list="uploadList"
                :action="`${$appBaseUrl}${OpenApiSecurity}/UploadChannelPublicPem?uuid=${data.uuid}`"
                :headers="fileUploadHeaders"
                :multiple="false"
                @change="handleChangeFile"
              >
                <a-button type="primary"> <a-icon type="upload" size="small"/>上传Pem</a-button>
              </a-upload>
            </template>
            <a-tag color="green">已上传</a-tag>
          </a-popover>
          <a-upload
            v-else
            name="file"
            :file-list="uploadList"
            :action="`${$appBaseUrl}${OpenApiSecurity}/UploadChannelPublicPem?uuid=${data.uuid}`"
            :headers="fileUploadHeaders"
            :multiple="false"
            @change="handleChangeFile"
          >
            <a-button type="primary"> <a-icon type="upload" size="small"/>上传Pem</a-button>
          </a-upload>
        </template>
        <template #signType="signType">
          <a-tag :color="signTypeEnum[signType].color">{{signTypeEnum[signType].text}}</a-tag>
        </template>
        <template #apiStatus="apiStatus">
          <a-tag v-if="apiStatus == 'Test'" color="purple">测试环境</a-tag>
          <a-tag v-else color="green">生产环境</a-tag>
        </template>
        <template #status="status">
          <a-tag :color="$statusEnum.baseStatus[status].color">{{$statusEnum.baseStatus[status].text}}</a-tag>
        </template>
        <template #createdAt="createdAt">
          {{ moment(createdAt).format("YYYY-MM-DD HH:mm:ss") }}
        </template>
        <template #action="data">
          <a-button
            v-if="
              $root.$data.privateState.role === 'Admin' ||
              $root.$data.privateState.role === 'SuperAdmin'
            "
            type="link"
            @click="editHandle(data)"
            >编辑</a-button
          >
          <a-button
            v-if="
              $root.$data.privateState.role === 'Admin' ||
              $root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Channel/Create', '/Channel/Edit')
            "
            type="link"
            @click="downloadPem(data)"
            >下载Api密钥</a-button
          >
          <a-popconfirm
            v-if="$root.$data.privateState.role === 'Admin' || $root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Channel/Create', '/Channel/Edit')"
            title="是否刷新Api密钥？刷新后将导致之前渠道无法访问接口，需要下载新Api密钥给渠道配置！"
            @confirm="refreshPemClick(data)"
            okText="是"
            cancelText="否"
          >
            <a-button href="javascript:;" type="link">刷新Api密钥</a-button>
          </a-popconfirm>
          <div v-if="$root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Channel/Edit')">
            <a-button v-if="data.status == 1" type="link" @click="changeStatus(data)">停用</a-button>
            <a-button v-if="data.status == -1" type="link" @click="changeStatus(data)">启用</a-button>
          </div>
          <a-popconfirm
            v-if="$root.$data.privateState.role === 'Admin' || $root.$data.privateState.role === 'SuperAdmin'"
            title="是否确认删除该渠道?"
            @confirm="deleteClick(data)"
            okText="是"
            cancelText="否"
          >
            <a-button href="javascript:;" type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      title="渠道Api信息"
      v-model="addShow"
      @ok="postDatas"
      :keyboard="false"
      :maskClosable="false"
      :width="1000"
    >
      <a-form-model
        ref="addForm"
        :model="addForm"
        :rules="addFormRules"
        v-viewer="{ movable: false }"
      >
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-model-item label="渠道名称" prop="name">
              <a-input v-model="addForm.name" />
            </a-form-model-item>
          </a-col>
          <a-col span="6">
            <a-form-model-item label="接口环境" prop="apiStatus">
              <a-select style="width:100%" v-model="addForm.apiStatus">
                <a-select-option value="Test">
                  测试环境
                </a-select-option>
                <a-select-option value="Production">
                  生产环境
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col span="6">
            <a-form-model-item label="渠道接口签名算法" prop="signType">
              <a-select :allowClear="true" style="width:100%" v-model="addForm.signType">
                <a-select-option v-for="it in signTypeList" :key="it.key" :value="it.key">
                  {{it.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <div v-if="actionMode === 'create'">
            <a-col span="6" >
              <a-form-model-item label="是否指定AppCode">
                <a-switch v-model="addForm.appointAppCode" checked-children="是" un-checked-children="否" />
              </a-form-model-item>
            </a-col>
            <a-col span="6">
              <a-form-model-item label="AppCode" v-if="addForm.appointAppCode">
                <a-input v-model="addForm.appCode" />
              </a-form-model-item>
            </a-col>
          </div>
          <a-col span="24">
            <a-form-model-item label="备注">
              <a-textarea
                :auto-size="{ minRows: 3, maxRows: 5 }"
                v-model="addForm.remarks"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import licia from "licia";
import DownloadJS from 'downloadjs'
import moment from "moment";
import screenfull from "screenfull";
import { OpenApiSecurity } from "@/api";
import { aliveVM, mixinTableList } from "@/utils/mixin";

const INIT_SEARCH_FORM =
  '{"Name":"","Status":""}';
const columns = [
  {
    title: "AppCode",
    width: 150,
    dataIndex: "uuid",
  },
  {
    title: "渠道名称",
    width: 150,
    dataIndex: "name",
  },
  {
    title: "渠道方公钥",
    width: 150,
    dataIndex: "channelPublicKey",
    scopedSlots: { customRender: 'channelPublicKey' },
  },
  {
    title: "签名算法",
    width: 150,
    dataIndex: "signType",
    scopedSlots: { customRender: 'signType' },
  },
  {
    title: '接口环境',
    dataIndex: 'apiStatus',
    scopedSlots: { customRender: 'apiStatus' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: "建立时间",
    width: 150,
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
  },
  {
    title: "操作",
    key: "operation",
    width: 130,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "ChannelTableList",
  mixins: [aliveVM, mixinTableList],
  created() {
    this.fileUploadHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    const baseColumns = [];
    for (const it of columns) {
      baseColumns.push(it.title);
    }
    this.$set(this, "baseColumns", baseColumns);
    this.fetch();
  },
  computed: {
    showColumns() {
      let c = [];
      for (const it of this.columns) {
        c.push(it.title);
      }
      return c;
    },
  },
  data() {
    return {
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${OpenApiSecurity}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      // 其他
      actionMode: 'create',
      OpenApiSecurity,
      moment,
      addForm: {
        name: '',
        apiStatus: '',
        signType: '',
        status: true,
        appointAppCode: false,
        appCode: '',
        remarks: "",
      },
      addFormRules: {
        name: [{ required: true, message: "请填写", trigger: "blur" }],
        signType: [{ required: true, message: "请选择", trigger: "change" }],
      },
      addShow: false,
      nowEditData: null,
      signTypeList: [
        { key: '2', name: 'Ed25519' },
        { key: '0', name: 'RSA' },
        { key: '1', name: 'ECDsa' }
      ],
      signTypeEnum: [
        { color: 'cyan', text: 'RSA' },
        { color: 'pink', text: 'ECDsa' },
        { color: 'purple', text: 'Ed25519' }
      ],
      // 导入Pem
      uploadList: [],
      fileUrl: '',
      fileUploadHeaders: {},
    };
  },
  methods: {
    changeColumns(v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it);
        }
      }
      this.$set(this, "columns", c);
    },
    async reload() {
      this.$set(this, "searchParams", null);
      this.searchForm.resetFields();
      await this.fetch();
    },
    async cleanSearchClick() {
      this.$set(this, "searchParams", null);
      this.$set(this, "searchForm", JSON.parse(INIT_SEARCH_FORM));
      await this.fetch();
    },
    async searchClick() {
      this.pagination.current = 1;
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      const searchParams = {};
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k];
        if (licia.isNum(it)) {
          searchParams[k] = it;
          continue;
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it;
        }
      }
      this.$set(this, "searchParams", searchParams);
      this.fetch();
    },
    // other
    addClick () {
      this.$set(this, 'addForm', {
        name: '',
        status: true,
        remarks: "",
      })
      this.actionMode = 'create'
      this.productTargetKeys = []
      this.nowEditData = null
      this.addShow = true
    },
    editHandle (data) {
      this.$set(this, 'addForm', {
        name: data.name,
        apiStatus: data.apiStatus,
        signType: `${data.signType}`,
        status: true,
        remarks: "",
      })
      this.actionMode = 'edit'
      this.nowEditData = data
      this.addShow = true
    },
    async postDatas() {
      this.$refs.addForm.validate(async (valid) => {
        if (!valid) {
          this.$message.warning("请检查填写内容");
          return;
        }
        let postData = JSON.parse(JSON.stringify(this.addForm));

        let res = {
          status: false,
          message: "未知请求状态",
        }
        if (this.nowEditData) {
          res = await this.$axios
            .put(`${OpenApiSecurity}/Edit?uuid=${this.nowEditData.uuid}`, postData);
          if (!res.status) {
            this.$message.error(res.message);
            return;
          }
          if (res.data && res.data.changeSignType) {
            this.$notification.open({
              message: '渠道信息已更新',
              description:
                '渠道接口签名类型已改变，请下载新的Api密钥，并上传对应类型的渠道密钥Pem文件',
              icon: <a-icon type="info-circle" style="color: #108ee9" />,
            });
          }
        } else {
          const res = await this.$axios
            .post(`${OpenApiSecurity}/Create`, postData);
          if (!res.status) {
            this.$message.error(res.message);
            return;
          }
        }
        
        this.addShow = false;
        this.$message.success("操作成功");
        this.fetch();
      })
    },
    // 产品选择穿梭组件 start
    onChangeCellRate(data, key, dataIndex, value) {
      const productSelectList = [...this.productSelectList];
      const target = productSelectList.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.productSelectList = productSelectList;
      }
      if (dataIndex === 'rate') {
        if (data.feeOfSection.length > 0) {
          let fee = NaN;
          for (const it of data.feeOfSection) {
            if (it.a <= value && value < it.b) {
              fee = it.fee
              break
            }
          }
          if (!isNaN(fee)) {
            target.fee = fee
          }
        }
      }
    },
    async downloadPem(data) {
      this.sending = true
      this.sendingHint = '正在获取数据'
      const apiRes = await this.$axios.get(`${OpenApiSecurity}/DownloadRsaPublic?uuid=${data.uuid}`).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      DownloadJS(window.atob(apiRes.data), `${data.uuid}.pem`)
    },
    async refreshPemClick(data) {
      this.sending = true
      this.sendingHint = '正在获取数据'
      const apiRes = await this.$axios.put(`${OpenApiSecurity}/RefreshPem?uuid=${data.uuid}`).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      this.$message.success("刷新Api密钥成功");
    },
    async changeStatus(data) {
      const apiRes = await this.$axios.put(`${OpenApiSecurity}/ChangeStatus?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.fetch()
    },
    async deleteClick (data) {
      this.loading = true
      const res = await this.$axios.delete(`${OpenApiSecurity}/Delete?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.loading = false
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.fetch()
    },
    handleChangeFile (info) {
      this.uploadList = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        this.uploadList = []
        return
      } else if (info.file.status === 'done') {
        this.uploadList = []
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          return;
        }
        this.$message.success('上传渠道Pem成功');
        this.fetch();
      }
    },
  },
};
</script>

<style>
</style>